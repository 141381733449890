<template>
  <v-app id="login">
    <v-content
      class="d-flex justify-center mb-6"
    >
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="d-flex justify-center mb-6"
          >
            <transition
              name="slideIn"
              type="animation"
              appear
            >
              <v-card
                id="patup"
                class="elevation-2 pa-1"
                width="300"
              >
                <v-alert
                  v-model="logError"
                  dismissible
                  type="error"
                >
                  Sorry Email or Password does not exist.
                </v-alert>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <img
                        width="240px"
                        src="https://res.cloudinary.com/fitrip/image/upload/v1622281785/belfastlogo_1_yskbx4.png"
                      >
                    </v-col>
                    <v-col
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-form>
                        <v-text-field
                          v-model="model.email"
                          dark
                          outlined
                          append-icon="mdi-at"
                          name="login"
                          label="Email"
                          type="text"
                          style="margin-bottom:-12px"
                          @keyup.enter="login"
                        />
                        <v-text-field
                          id="password"
                          v-model="model.password"
                          dark
                          outlined
                          preppend-icon="mdi-lock"
                          name="password"
                          label="Password"
                          :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="isPasswordVisible ? 'text' : 'password'"
                          style="margin-bottom:-19px"
                          @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                          @keyup.enter="login"
                        />
                      </v-form>
                      <v-card-actions>
                        <v-btn
                          block
                          color="primary"
                          class="elevation-8"
                          :loading="loading"
                          @click="login"
                        >
                          Login
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import * as Cookies from 'js-cookie'
  const axios = require('axios')

  export default {
    data: () => ({
      kit: false,
      loading: false,
      logError: false,
      isPasswordVisible: false,
      model: {
        email: '',
        password: '',
      },
    }),
    computed: {

    },
    watch: {

    },
    mounted () {
      localStorage.clear()
      this.hideHead()
    },
    methods: {
      login () {
        this.loading = true
        this.logError = false
        const self = this
        axios.post(this.apiSet.login, this.model)
          .then(function (response) {
            const uid = response.data
            self.loading = false
            // self.$store.commit('setAuth', uid)
            Cookies.set('auth', uid)
            localStorage.setItem('token', response.data.token)
            axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
            self.$store.commit('setUserStore', response.data.token)
            self.$store.commit('setSnack', { t: 'Welcome ' + self.model.email, c: 'green darken-3' })
            setTimeout(() => {
              self.$store.dispatch('loadUserData', response.data.token)
              self.$router.push('/dashboard')
            }, 3000)
          }).catch(
            error => {
              self.loading = false
              self.logError = true
              self.err.push(error)
            },
          )
      },

      hideHead () {
        this.$store.commit('setBlankSlate', false)
      },

    },

  }
</script>
<style scoped lang="css">
    #login {
        background-size:contain;
        height: 100%;
        width: 100%;
        margin-left:0px;
        background:#EEF7FB;
        /*background-image:url('/static/img/sethoscope.jpg');*/
        /*background:url('https://res.cloudinary.com/fitrip/image/upload/v1619483858/db6bd0e335592dda1da247bf36f30814_wkq3vi.jpg');*/
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        z-index: 0;
    }
    #patup {
        /*background:rgba(255,255,255,0.9);*/
        background:url('https://res.cloudinary.com/fitrip/image/upload/v1619484637/elegant-seamless-pattern-black-circles-with-gold-dots-on-dark-background-texture-vector_n5pntd.jpg');
        border:4px solid rgba(255,255,255,0.6);
        border-radius: 10px;
    }

    h3 {
        font-family: "Ubuntu", "ChunkFiveRegular", "Century Gothic", CenturyGothic, "AppleGothic", sans-serif;
        font-size: 52px;
        padding: 8px 5px;
        text-align: center;
        text-transform: uppercase;
        text-rendering: optimizeLegibility;
    }
    h3.elegantshadow {
        color: #074D64;
        /*background-color: #e7e5e4;*/
        letter-spacing: 0.15em;
        /*text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 2px 1px #767474, -1px 6px 1px #787777, -4px 2px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;*/
        /*text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 2px 1px #767474, -1px 6px 1px #787777, -4px 2px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;*/
        text-shadow: -1px -1px 1px #e3f2fd, 2px 2px 1px #e3e3e3;

    }
    h1.deepshadow {
        color: #e0dfdc;
        background-color: #333;
        letter-spacing: 0.1em;
        text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9);
    }
    h1.insetshadow {
        color: #202020;
        background-color: #2d2d2d;
        letter-spacing: 0.1em;
        text-shadow: -1px -1px 1px #111, 2px 2px 1px #363636;
    }
    h1.retroshadow {
        color: #2c2c2c;
        background-color: #d5d5d5;
        letter-spacing: 0.05em;
        text-shadow: 4px 4px 0px #d5d5d5, 7px 7px 0px rgba(0, 0, 0, 0.2);
    }

</style>
